.project {
  padding: 2em;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 40%;
}

.project:hover {
  transform: translateY(-7px);
}

.project-description {
  margin-top: 1em;
}

.project-stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.project-stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.project .link-icon {
  margin-left: 0.5em;
}

.pic {
  width: 12.0em;
  height: 6.0em;
}
