.about {
  flex-direction: column;
  margin-top: 3em;
}

.about-name {
  color: var(--clr-primary);
}

.about-role {
  margin-top: 1.2em;
}

.about-desc {
  font-size: 1rem;
  max-width: 600px;
}

.about-desc,
.about-contact {
  margin-top: 2.4em;
}

.about .link-icon {
  margin-right: 0.8em;
}

.about .btn-outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
  }

  .center {
    display: flex;
    align-items: center;
  }
}

.profile-img {
  height:15em;
  width: 15em;
  border-radius: 50%;
  box-shadow: 0 4px 6px 0 var(--clr-primary);
  transition: all ease-in-out .2s;
}

.profile-img:hover {
  box-shadow: 0 8px 12px 0 var(--clr-primary);
}

.profile-img-container {
  display: flex;
  justify-content: center;
  margin-bottom: 3em;
}
